
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SignInForm',

  data: () => {
    return {
      form: {
        email: '',
        password: ''
      },
      snackbar: false,
      snackbarText: ''
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/isAuth',
      dealer: 'dealers/currentDealer'
    }),
    obs () : any {
      return this.$refs.observer as any
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async submit () {
      const isValid = await this.obs.validate()
      if (isValid) {
        const response = await this.signIn(this.form)
        if (this.authenticated && response === 200) {
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect.toString())
          } else {
            this.$router.replace('/dealers')
          }
        } else {
          this.snackbarText = 'One or more of the fields were invalid. Please try again.'
          this.snackbar = true
        }
      }
    }
  }
})
