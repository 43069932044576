

import Vue from 'vue'
import { mapGetters } from 'vuex'
import { NetworkService } from '../../services/NetworkService'
import { Dealer } from '@/models/Dealer'
import { DealerAnalytics } from '@/models/DealerAnalytics'
import DateRangePicker from '@/components/DateRangePicker.vue'
import { Product } from '@/models/Product'

export default Vue.extend({
  props: {
    source: String
  },
  data () {
    return {
      headers: [
        {
          text: 'Dealer Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Dealer Code', value: 'code' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Active', value: 'active' },
        { text: '', sortable: false, value: 'actions' }
      ],
      analyticsHeaders: [
        {
          text: 'Dealer Name',
          align: 'start',
          value: 'name'
        },
        { text: 'Sales', value: 'sales' },
        { text: 'Users', value: 'users' },
        { text: 'New Users', value: 'newUsers' },
        { text: 'Sessions', value: 'sessions' },
        { text: 'Page Views', value: 'pageViews' },
        { text: 'Unique Page Views', value: 'uniquePageViews' },
        { text: 'Average Time On Page', value: 'avgTimeOnPage' },
        { text: 'Bounce Rate', value: 'bounceRate' },
        { text: 'Customer Sign Ups', value: 'customerSignUps' }
      ],
      productHeaders: [
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        },
        { text: 'Number', value: 'number'},
        { text: 'Views', value: 'views' }
      ],
      dealers: [] as Dealer[],
      dealerAnalyticsObjects: [] as any[],
      confirmationDialog: false as boolean,
      snackbarText: '' as string,
      snackbar: false as boolean,
      selectedDealer: null as Dealer,
      loading: false as boolean,
      dialogText: '' as string,
      dialogTitle: '' as string,
      dialogButtonText: '' as string,
      dialogButtonColour: '' as string,
      analytics: [] as Array<DealerAnalytics>,
      productAnalytics: [] as any[],
    }
  },
  components: {
    DateRangePicker
  },
  computed: {
    ...mapGetters({
      dealersLoading: 'dealers/loading'
    }),
    cols() : number {
      const { xs, sm } = this.$vuetify.breakpoint
      return (xs || sm) ? 12 : 4
    },
  },
   mounted() {
    this.getDealers()
  },
  methods: {
    getDealers () {
      this.$store.dispatch('dealers/reloadDealers')
      this.dealers = this.$store.state.dealers.list
    },
    async requestStatistics(dateRange : String[]) {
      this.analytics = []
      await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/dealers/getAnalytics?startDate=' + dateRange[0] + '&endDate=' + dateRange[1]).then(response => {
        this.createAnalyticsObject(response.data.data)
      }).catch(error => console.log(error))

      await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/analytics/getProductSummary?startDate=' + dateRange[0] + '&endDate='+
      dateRange[1]).then(response => {
        console.log(response)
        this.createProductAnalyticsObject(response.data.report.reports[0].data.rows)
      }).catch(error => console.log(error))
    },
    async createProductAnalyticsObject(productAnalytics : any) {
      this.productAnalytics = []
      let hondaProducts : any[] = []
      let KIAProducts : any[] = []
      let nissanProducts : any[] = []
      let subaruProducts : any[] = []
      let acuraProducts: any[] = []

      productAnalytics.forEach(((element : any) => {
        if (element.dimensions[0].includes('products/')) {
          console.log(element.dimensions[0].split('/')[1])
          switch(element.dimensions[0].split('/')[1]) {
            case 'honda':
              hondaProducts.push({ make: element.dimensions[0].split('/')[1], productId: element.dimensions[0].split('/')[4], views: element.metrics[0].values[0], name: '', number: 0 })
              break
            case 'kia':
              KIAProducts.push({ make: element.dimensions[0].split('/')[1], productId: element.dimensions[0].split('/')[4], views: element.metrics[0].values[0], name: '', number: 0 })
              break
            case 'nissan':
              nissanProducts.push({ make: element.dimensions[0].split('/')[1], productId: element.dimensions[0].split('/')[4], views: element.metrics[0].values[0], name: '', number: 0 })
              break
            case 'subaru':
              subaruProducts.push({ make: element.dimensions[0].split('/')[1], productId: element.dimensions[0].split('/')[4], views: element.metrics[0].values[0], name: '', number: 0 })
              break
            case 'acura':
              acuraProducts.push({ make: element.dimensions[0].split('/')[1], productId: element.dimensions[0].split('/')[4], views: element.metrics[0].values[0], name: '', number: 0 })
              break
            default:
              break
          }
        }
      }))
      hondaProducts = hondaProducts.sort((a, b) => b.views - a.views).slice(0,5)
      KIAProducts = KIAProducts.sort((a, b) => b.views - a.views).slice(0,5)
      subaruProducts = subaruProducts.sort((a, b) => b.views - a.views).slice(0,5)
      nissanProducts = nissanProducts.sort((a, b) => b.views - a.views).slice(0,5)
      acuraProducts = acuraProducts.sort((a, b) => b.views - a.views).slice(0,5)
      console.log(KIAProducts)
      console.log(subaruProducts)
      console.log(acuraProducts)
      console.log(nissanProducts)

      this.productAnalytics = hondaProducts.concat(KIAProducts, subaruProducts, nissanProducts, acuraProducts)
      console.log(this.productAnalytics)

      const productIds : string[] = []
      this.productAnalytics.forEach((element : any) => {
        productIds.push(element.productId)
      })

      await NetworkService.post(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/products/getProductsById',{...this.prepareRequestJson(productIds)}).then(response => {
        console.log(response)
        response.data.data.forEach((product : Product) => {
          this.productAnalytics.forEach((element : any) => {
            if (product.id == element.productId) {
              element.name = product.name
              element.number = product.number
            }
          })
        })
      })
    },
    mostViewedProducts(make : string) {
      const makeSpecificProducts : any[] = []
      this.productAnalytics.forEach((element : any) => {
        if (element.make.toLowerCase() == make.toLowerCase()) {
          makeSpecificProducts.push(element)
        }
      })
      return makeSpecificProducts
    },
    prepareRequestJson(productIds : string[]) {
      let request = {
        productIds: productIds
      }
      return request
    },
    createAnalyticsObject(dealerAnalyticsArray : DealerAnalytics[]) : void {
      dealerAnalyticsArray.forEach((element : DealerAnalytics) => {
        const result = this.analytics.filter(obj => {
          return obj.dealerId == element.dealerId
        })
        if (result.length > 0) {
          result[0].sales += element.sales
          result[0].users += element.users
          result[0].newUsers += element.newUsers
          result[0].customerSignUps += element.customerSignUps
          result[0].sessions += element.sessions
          result[0].pageViews += element.pageViews
          result[0].uniquePageViews += element.uniquePageViews
          result[0].avgTimeOnPage += element.avgTimeOnPage
          result[0].bounceRate += element.bounceRate
        } else {
          this.analytics.push(element)
        }
      })
    },
    async handleRowClick (item : any) {
      await this.$store.dispatch('dealers/loadDealer', { code: item.code, makeId: item.make.id })
      this.$router.push('/dealers/' + item.id + '/edit')
    },
    handleClick(item: Dealer) {
      this.selectedDealer = item
        if(item.active == true) {
        this.dialogButtonColour = 'error'
        this.dialogText = 'Are you sure you want to deactivate this dealership?'
        this.dialogTitle = 'Deactivate Dealership'
        this.dialogButtonText = 'Deactivate'
      } else {
        this.dialogButtonColour = 'success'
        this.dialogText = 'Are you sure you want to activate this dealership?'
        this.dialogTitle = 'Activate Dealership'
        this.dialogButtonText = 'Activate'
      }
      this.confirmationDialog = true
    },
    async changeStatus(item: Dealer) {
      this.loading = true
      const response = await Dealer.changeStatus(item.id)
      this.loading = false
      this.confirmationDialog = false
      if (response === 200) {
        if (item.active == true) {
          this.snackbarText = 'The selected dealer has been successfully deactivated.'
        } else {
          this.snackbarText = 'The selected dealer has been successfully activated.'
        }
        this.getDealers()
      } else {
        if (item.active == true) {
          this.snackbarText = 'There was an error attempting to deactivate the dealer.'
        } else {
          this.snackbarText = 'There was an error attempting to activate the dealer.'
        }
      }
      this.snackbar = true
    },
  }
})
