import { NetworkService } from "../services/NetworkService"
import { Model } from "./Model"

interface ITrim {
  id: string
  active: boolean
  name: string
}

export class Trim implements ITrim {
  id!: string
  name!: string
  active = true
  model: Model

  constructor(params: ITrim) {
    Object.assign(this, params)
  }

  public static async getTrims(modelId = ""): Promise<any> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
        "/api/v1/auth/trims" +
        (modelId ? "?modelId=" + modelId : "")
    )

    const trims: Trim[] = []
    res.data.forEach((t: any) => {
      const trim: Trim = new Trim(t)
      trims.push(trim)
    })

    return trims
  }

  public static async getTrim(trimId: number): Promise<Trim | null> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/trims/" + trimId
    )
    return new Trim(res.data)
  }

  public static async addNew(trim: any): Promise<number | null> {
    const data = new FormData()
    data.append("image", trim.image)
    data.append("make", trim.make)
    data.append("model_id", trim.modelId)
    data.append("name", trim.name)
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/trims/create_trim",
      data
    )
    return Promise.resolve(res.status)
  }

  public static async uploadImage(
    file: any,
    make: string,
    model: string,
    trim: string
  ): Promise<any> {
    const data = new FormData()
    data.append("file", file)
    data.append("make", make)
    data.append("model", model)
    data.append("trim", trim)
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/trims/upload_image/",
      data
    )
    return Promise.resolve(res.status)
  }

  public static async getTrimsByMake(makeId: string): Promise<Trim[]> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/trims/" + makeId
    )

    let trims: Trim[] = []
    res.data.trims.forEach((t: any) => {
      const trim: Trim = new Trim(t)
      trims.push(trim)
    })

    trims = trims.sort((a, b) => a.model.year.year - b.model.year.year)

    return Promise.resolve(trims)
  }

  public static async changeStatus(id: string) {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL +
        "/api/v1/auth/trims/change_status/" +
        id
    )

    return Promise.resolve(res.status)
  }

  public static async updateName(id: string, name: string): Promise<any> {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/trims/update_name/",
      { id, name }
    )
    return Promise.resolve(res.status)
  }
}
