
import Vue from 'vue'

import { mapGetters } from 'vuex'

import { Product } from '../../models/Product'

export default Vue.extend({
  props: {
    source: String
  },
  data () {
    return {
      search: '' as string,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Product Number', value: 'number' },
        { text: 'Price', value: 'price' },
        { text: 'Images', value: 'images'},
        { text: 'Updated At', value: 'updatedAt'}
      ],
      products: [] as Product[],
      uploadProducts: [] as Product[],
      totalProducts: 0 as number,
      options: {
      },
      loading: false as boolean,
      csvDialog: false as boolean,
      confirmationDialog: false as boolean,
      csvFile: null as any,
      showCsvError: false as boolean,
      data: null as any,
      snackbar: false as boolean
    }
  },
  computed: {
    ...mapGetters({
      make: 'makes/currentMake'
    }),
  },
  methods: {
    async getProducts () {
      this.loading = true
      const res = await Product.getProducts(this.make.id, this.options, this.search)
      this.products = res.products
      this.totalProducts = res.total
      this.loading = false
    },
    handleCreateClick () {
      this.$router.push('/products/create')
    },
    onFileChange(e: any) {
      this.csvFile = e
    },
    async handleUploadClick () {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.csvFile)
      const res = await Product.uploadProductsCSV(formData)
      if (res != -1) {
        this.uploadProducts = res
        this.loading = false
        this.csvDialog = false
        this.confirmationDialog = true
      } else {
        this.loading = false
        this.csvDialog = false
        this.snackbar = true
      }
    },
    async handleRowClick (item : any) {
      await this.$store.dispatch('products/loadProduct', item.id)
      this.$router.push('/products/' + item.id + '/edit')
    },
    checkImages(item : any) : string {
      return item.images.length > 0 ? 'Yes' : 'No'
    },
    parseDate(item: any) {
      return item.substring(0, 10)
    }
  }
})
