<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="deep-purple accent-4"
      class="elevation-2"
      dark
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="i in tab_keys" :key="i" :href="`#tab-${i}`">
        {{ i }}
      </v-tab>

      <v-tab-item :key="tab_keys[0]" :value="'tab-' + tab_keys[0]">
        <v-card flat tile>
          <Products />
        </v-card>
      </v-tab-item>
      <v-tab-item :key="tab_keys[1]" :value="'tab-' + tab_keys[1]">
        <Models />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Products from "../Products/Products.vue"
import Models from "../Models/Models.vue"

export default {
  props: {
    source: String
  },
  components: {
    Products,
    Models
  },
  data() {
    return {
      make: {},
      make_id: this.$route.params.dealer_id,
      loading_complete: false,
      patch_success: false,
      is_editing: this.$route.meta.is_editing,
      error_message: "",
      snackbar: false,
      snackbar_text: "",

      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      tabs: 2,
      tab_keys: ["Products", "Models"],

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Product Number", value: "number" },
        { text: "Price", value: "price" }
      ]
    }
  },
  mounted: function () {
    this.initialSetup()
  },
  methods: {
    initialSetup() {
      if (this.is_editing) {
        this.getMake()
      } else {
        this.make = {
          name: ""
        }
        this.loading_complete = true
      }
    },
    getMake() {
      this.make = this.$store.state.makes.currentMake
      this.loading_complete = true
    }
  }
}
</script>
