import { NetworkService } from "../services/NetworkService"

interface IModel {
  id: string
  name?: string
  yearId?: number
  makeId?: string
  active?: boolean
}

export class Model implements IModel {
  id!: string
  name!: string
  makeId!: string
  yearId!: number
  active!: boolean
  year: any

  constructor(params: IModel) {
    Object.assign(this, params)
  }

  public static async getModels(makeId: string): Promise<Model[]> {
    const models: Model[] = []

    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/models?makeId=" + makeId
    )
    res.data.data.forEach((m: Model) => {
      const model: Model = new Model(m)
      models.push(model)
    })

    return Promise.resolve(models)
  }

  public static async addNew(m: any): Promise<number | null> {
    const data = new FormData()
    data.append("image", m.image)
    data.append("make_id", m.makeId)
    data.append("make", m.make)
    data.append("name", m.name)
    data.append("year", m.year)
    data.append("trim_name", m.trim)
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/models/add",
      data
    )
    return Promise.resolve(res.status)
  }

  public static async changeStatus(modelId: string) {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL +
        "/api/v1/auth/models/change_status/" +
        modelId
    )

    return Promise.resolve(res.status)
  }

  public static async updateName(id: string, name: string): Promise<any> {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/models/update_name/",
      { id, name }
    )
    return Promise.resolve(res.status)
  }

  public static async uploadImage(
    file: any,
    make: string,
    model: string
  ): Promise<any> {
    const data = new FormData()
    data.append("file", file)
    data.append("make", make)
    data.append("model", model)

    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL + "/api/v1/auth/models/upload_image/",
      data
    )
    return Promise.resolve(res.status)
  }
}
