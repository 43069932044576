
import Vue from "vue"

import { mapGetters } from "vuex"

import { Model } from "@/models/Model"
import { Trim } from "@/models/Trim"

import { ValidationObserver } from "vee-validate"

export default Vue.extend({
  data() {
    return {
      search: "" as string,
      headers: [
        { text: "Model Name", value: "name" },
        { text: "Active", value: "active" },
        { value: "overflow" }
      ],
      currentModel: null as Model,
      currentModelName: null as string,
      currentTrimName: null as string,
      currentTrim: null as Trim,
      modelDialog: false as boolean,
      trimDialog: false as boolean,
      trims: [] as Trim[],
      loading: false as boolean,
      snackbarText: "",
      snackbar: false,
      dialogText: "" as string,
      dialogTitle: "" as string,
      dialogButtonText: "" as string,
      dialogButtonColour: "" as string,
      selectedModel: null as Model,
      selectedTrim: null as Trim,
      modelImage: null as string,
      trimImage: null as string,
      modelImageForUpload: null as any,
      trimImageForUpload: null as any,
      addTrimDialog: false,
      trimToAdd: null,
      modelToAdd: null,
      addModelDialog: false,
      years: [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025]
    }
  },
  computed: {
    ...mapGetters({
      make: "makes/currentMake",
      models: "models/models"
    }),
    showModelNameSave(): boolean {
      if (this.currentModelName !== this.currentModel.name) return true
      return false
    },
    showModelImageUpload(): boolean {
      return this.modelImageForUpload !== null
    },
    showTrimImageUpload(): boolean {
      return this.trimImageForUpload !== null
    },
    showTrimNameSave(): boolean {
      if (this.currentTrimName !== this.currentTrim.name) return true
      return false
    },
    emptyImage(): string {
      return "https://res.cloudinary.com/shopdesk-images/image/upload/c_scale,w_512/empty/empty_vehicle.png"
    }
  },
  mounted() {
    this.getTrims()
  },
  methods: {
    customFilter(value: any, search: string | null, t: any): boolean {
      const year = String(t.model.year.year) || ""
      const searchTerms = search.toLocaleLowerCase().split(" ")

      const words = [
        t.name.toLocaleLowerCase(),
        t.model.name.toLocaleLowerCase(),
        year
      ]

      return searchTerms.every((searchTerm) =>
        words.some((word) => word.includes(searchTerm))
      )
    },
    addTrim(m: any) {
      this.currentModel = m.model
      this.trimToAdd = {
        modelId: m.model.id,
        name: "",
        image: null,
        make: this.getShopdeskMakeId(m.model.makeId)
      }
      this.addTrimDialog = true
    },
    addModel() {
      this.modelToAdd = {
        name: "",
        year: 2024,
        image: null,
        makeId: this.make.id,
        make: this.getShopdeskMakeId(this.make.id),
        trim: ""
      }
      this.addModelDialog = true
    },
    modelImageChange(file: any) {
      this.modelImageForUpload = file
    },
    trimImageChange(file: any) {
      this.trimImageForUpload = file
    },
    async checkImage(url: string) {
      try {
        const response = await fetch(url)

        return Promise.resolve(response.ok)
      } catch (error) {
        return Promise.resolve(false)
      }
    },
    getShopdeskMakeId(makeName: string): string {
      switch (makeName) {
        case "honda":
          return "xaDKkUP7UzZp9HCmuCAo"
        case "kia":
          return "aGhdylAk8p7xMJUMvsnR"
        case "nissan":
          return "QTukLdsCilD0MqPuXsVX"
        case "subaru":
          return "MWr0MYlUttbzzDRuGeRn"
        default:
          return null
      }
    },
    modelClicked(m: Model) {
      if (m !== null) {
        this.currentModel = m
        this.modelDialog = true
        this.currentModelName = m.name

        if (
          this.currentModel &&
          this.currentModel.makeId &&
          this.currentModel.id
        ) {
          const id = this.getShopdeskMakeId(this.currentModel.makeId)
          this.modelImage = `https://res.cloudinary.com/shopdesk-images/image/upload/images/manufacturers/${id}/vehicles/${
            this.currentModel.id
          }/vehicle.png?${Math.floor(Math.random() * 10000)}`
        }
      }
    },
    trimClicked(t: Trim) {
      if (t !== null) {
        this.currentTrim = t
        this.currentTrimName = t.name
        this.trimDialog = true

        if (this.currentTrim && this.currentTrim.model && this.currentTrim.id) {
          const id = this.getShopdeskMakeId(this.make.id)
          this.trimImage = `https://res.cloudinary.com/shopdesk-images/image/upload/images/manufacturers/${id}/vehicles/${
            this.currentTrim.model.id
          }/trims/${this.currentTrim.id}/vehicle.png?${Math.floor(
            Math.random() * 10000
          )}`
        }
      }
    },
    async getTrims() {
      this.loading = true
      this.trims = await Trim.getTrimsByMake(this.make.id)
      this.loading = false
    },
    async changeModelStatus(item: Model) {
      this.loading = true
      const response = await Model.changeStatus(item.id)
      this.loading = false
      this.modelDialog = false
      if (response === 200) {
        if (item.active == true) {
          this.snackbarText =
            "The selected model has been successfully deactivated."
        } else {
          this.snackbarText =
            "The selected model has been successfully activated."
        }
        this.getTrims()
      } else {
        if (item.active == true) {
          this.snackbarText =
            "There was an error attempting to deactivate the model."
        } else {
          this.snackbarText =
            "There was an error attempting to activate the model."
        }
      }
      this.snackbar = true
    },
    async changeTrimStatus(item: Trim) {
      this.loading = true
      const response = await Trim.changeStatus(item.id)
      this.loading = false
      this.trimDialog = false
      if (response === 200) {
        if (item.active === true) {
          this.snackbarText =
            "The selected trim has been successfully deactivated."
        } else {
          this.snackbarText =
            "The selected trim has been successfully activated."
        }
        this.getTrims()
      } else {
        if (item.active == true) {
          this.snackbarText =
            "There was an error attempting to deactivate the trim."
        } else {
          this.snackbarText =
            "There was an error attempting to activate the trim."
        }
      }
      this.snackbar = true
    },
    async updateModelName(m: Model) {
      const ref = this.$refs.modelDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        const response = await Model.updateName(m.id, m.name)
        this.currentModelName = m.name
        this.loading = false
        if (response === 200) {
          this.snackbarText =
            "The selected model has been successfully updated."
        } else {
          this.snackbarText =
            "There was an error attempting to update the model."
        }
        this.snackbar = true
      }
    },
    async updateTrimName(t: Trim) {
      const ref = this.$refs.trimDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        const response = await Trim.updateName(t.id, t.name)
        this.currentTrimName = t.name
        this.loading = false
        if (response === 200) {
          this.snackbarText = "The selected trim has been successfully updated."
        } else {
          this.snackbarText =
            "There was an error attempting to update the trim."
        }
        this.snackbar = true
      }
    },
    async saveNewTrim() {
      const ref = this.$refs.addTrimDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        const response = await Trim.addNew(this.trimToAdd)
        this.loading = false
        this.addTrimDialog = false
        this.getTrims()
        if (response === 200) {
          this.snackbarText = "The selected trim has been successfully saved."
        } else {
          this.snackbarText = "There was an error attempting to save the trim."
        }
        this.snackbar = true
      }
    },
    async saveNewModel() {
      const ref = this.$refs.addModelDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        const response = await Model.addNew(this.modelToAdd)
        this.loading = false
        this.addModelDialog = false
        this.getTrims()
        if (response === 200) {
          this.snackbarText = "The selected model has been successfully saved."
        } else {
          this.snackbarText = "There was an error attempting to save the model."
        }
        this.snackbar = true
      }
    },
    async uploadModelImage(m: Model) {
      const ref = this.$refs.modelDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        try {
          console.log("Beginning upload...")
          const response = await Model.uploadImage(
            this.modelImageForUpload,
            this.getShopdeskMakeId(this.currentModel.makeId),
            this.currentModel.id
          )
          console.log("Upload complete...")
          if (response === 200) {
            this.snackbarText =
              "The selected model has been successfully updated."
            this.modelImage = null
            await this.$nextTick()
            setTimeout(() => {
              this.modelImage = `https://res.cloudinary.com/shopdesk-images/image/upload/images/manufacturers/${this.getShopdeskMakeId(
                this.currentModel.makeId
              )}/vehicles/${this.currentModel.id}/vehicle.png?${Math.floor(
                Math.random() * 10000
              )}`
            }, 2000)

            console.log(this.modelImage)
          } else {
            this.snackbarText =
              "There was an error attempting to update the model."
          }
        } catch (error) {
          this.snackbarText =
            "There was an error attempting to update the model."
        } finally {
          this.loading = false
          this.snackbar = true
          this.modelImageForUpload = null
        }
      }
    },
    async uploadTrimImage(m: Trim) {
      const ref = this.$refs.trimDialogObserver as InstanceType<
        typeof ValidationObserver
      >
      const isValid = await ref.validate()
      if (isValid) {
        this.loading = true
        try {
          const response = await Trim.uploadImage(
            this.trimImageForUpload,
            this.getShopdeskMakeId(this.make.id),
            this.currentTrim.model.id,
            this.currentTrim.id
          )
          if (response === 200) {
            this.snackbarText =
              "The selected model has been successfully updated."
            this.trimImage = null
            await this.$nextTick()
            setTimeout(() => {
              this.trimImage = `https://res.cloudinary.com/shopdesk-images/image/upload/images/manufacturers/${this.getShopdeskMakeId(
                this.make.id
              )}/vehicles/${this.currentTrim.model.id}/trims/${
                this.currentTrim.id
              }/vehicle.png?${Math.floor(Math.random() * 10000)}`
            }, 2000)
          } else {
            this.snackbarText =
              "There was an error attempting to update the trim."
          }
        } catch (error) {
          this.snackbarText =
            "There was an error attempting to update the trim."
        } finally {
          this.loading = false
          this.snackbar = true
          this.modelImageForUpload = null
        }
      }
    }
  }
})
